#section-home{
  text-align: center;
  h1 {
    font-size: 40px;
    line-height: 50px;
  }
  p {
    font-size: 30px;
  }
  u {
    text-decoration-color: $brand-color;
  }
}

section#section-portfolio-home{
  .main-pic {
    max-width: 100%;
    width: auto;
    margin-top: 100px;
    max-height: 65vh;
  }
  text-align: center;
  h1 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0;
  }
  p {
    font-size: 20px;
  }
  u {
    text-decoration-color: $brand-color;
  }
}
