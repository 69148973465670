@import "0-libs/!dir";
@import "1-tools/!dir";
@import "2-settings/!dir";
@import "3-elements/!dir";
@import "4-objects/!dir";
@import "5-components/!dir";


section>.container{
//    margin-top: 80px;
}

section#values{
    img {
        max-height: 200px;
    }
}

.navbar {
  backdrop-filter: blur(10px);
  position: absolute;
  left: 0px;
  right: 20px;
}
.navbar-brand img {
  height: 50px;
  // margin-left: -20px;
}

@media (max-width: 768px) {
  section#about-me img{
    margin: 80px auto 20px;
  }

  section img {
    width: 50%;
    height: auto;
    margin: auto;
    display: flex;
  }
  section#values{
    img {
        max-height: 200px;
    }
}
.scroll-container {
    width: 100%;
    min-height: 100vh; 
  
    section,
    footer {
      width: 100%;
      min-height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 50px; 
      background: $background-color;
      background: radial-gradient(
        circle,
        rgb(33, 33, 33) 0%,
        rgba($background-color, 1)
      );
    }
  }
}
section#numbers {
  text-align: center;
  span.expressive {
    font-size: 6rem;
  }

  span {
    font-size: 3rem;
    line-height: 2rem;
  }
  p {
     max-width: 200px;
     margin: auto;
  }
}
@media (min-width: 768px) {

  
  .collapse.navbar-collapse {
    justify-content: space-between;
  }

  .scroll-container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
  
    section,
    footer {
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 50px;
      scroll-snap-align: start;
      background: $background-color;
      background: radial-gradient(
        circle,
        rgb(33, 33, 33) 0%,
        rgba($background-color, 1)
      );
    }
  }
}

.replaced-svg {
  transform: scale(2.5, 2.5);
  transform-origin: right;

  path {
    fill: $font-color;
    stroke: $brand-color;
    stroke-width: 1px;
    stroke-dasharray: 125;
    stroke-dashoffset: -125;
    stroke-linecap: butt;
    @include transition(all 1s ease-out);
  }

  &:hover > path {
    fill: transparent;
    stroke-dashoffset: 0;
    stroke-dasharray: 125;
  }
}

#about-me {
  img {
    width: auto;
    height: 200px;
  }

  #social {
    .list-inline {
      display: block;
    }
    .list-inline-item:not(:last-child) {
        margin-right: 2.5rem;
    }
  }
} 


///accordion
.accordion-item {
  background-color: #161616;
  color: $font-color;
  border: $background-color;
  box-shadow: none;
  margin-bottom: 4px;
}

button.accordion-button {
  background-color: #2b2b2b;
  color: $font-color;
  font-weight: 600;
}
.accordion-button:not(.collapsed) {
  background-color: $brand-color;
  box-shadow: none;
  color: $font-color;
}
.accordion {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.btn-primary {
  background-color: $brand-color;
  border: $brand-color;

  line-height: 1.8rem;
  &:hover {
    background-color: (darken($brand-color, 10%));
  }
}

section#nothing {
  img {
    max-height: 400px;
    margin: auto; 
    display: block;
  }
}

footer {
  form {
    max-width: 500px;
    margin: auto;
  }
}

section.post-content {
  img {
    width: auto;
    max-width: 100%;
    max-height: 60vh;
  }
}
section.portfolio-content {
  padding-top: 100px;
  height: auto;
  img {
    width: auto;
    max-width: 100%;
    max-height: 60vh;
  }
  h1,h2,h3,h4,h5,h6{
    margin-top: 2rem;
  }

}

.form-control {
  margin: 10px auto;
}
form button{
width: 100%;
}

